.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}



#root .logout-button {
  display: flex;
  justify-content: flex-end;
  text-align: center;
  margin: auto;
}

#root .login-form {
  max-width: 300px;
}

#root .login-card {
  width: 350px;
}

#root {
  height: 100%;
}

.main-container {
  height: 100%;
}

.login-card-container {
  padding-top: 1%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.content-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.layout {
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

.content {
  flex-grow: 1;
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

.inner-content {
  padding: 24px 0;
  background: #fff;
  flex-grow: 1;
}
.ant-table-placeholder {
  min-height: 200px;
}

@media (min-width: 992px) { 
  .content {
    padding: 0 10px;
  }
}

@media (min-width: 1200px) { 
  .content {
    padding: 0 50px;
  }
}

#root .login-form-forgot {
  float: right;
}

#root .login-form-button {
  width: 100%;
}

#root .site-footer {
  position: absolute;
  bottom: 0;
  width: 100%;
}

#root .menu-text {
  display: inline-block;
}

#root .empty-add-button {
  margin: 0 auto;
}

.header .logo img {
  height: 50px;
  margin-right: 10px;
}

.header .logo {
  color: #FFF;
  font-size: 18px;
}

